<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/programs/ra-aus-flight-instructor-course-800.png')" title="MULTI-ENGINE RATING COURSE" subtitle="Two is better than one" />
    <div class="program-description">
      <h1>What is the Multi-Engine Rating Course?</h1>
      <p>
        The multi-engine class rating allows you to fly a multi-engine aeroplane. You must have a minimum of a PPL(A) or a CPL(A) and at the end of the course, successfully complete a flight test.
      <br>

      <h1>Requirements</h1>
      <div class="list-container">
        <ul class="program-list">
          <li>Private Pilot Licence or higher</li>
          <li>Competency-based</li>
        </ul>
      </div>

      <br>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
